import React from "react";
import { Link } from "react-router-dom";
import { FaPaperPlane } from "react-icons/fa";
import { FaMapMarkerAlt, FaEnvelopeOpen, FaFacebookF } from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import swal from "sweetalert";

const Footer = () => {
  const sendForm = (e) => {
    e.preventDefault();
    swal({
      title: "Email Sent Successfully",
      text: "Thank you! We've received your message. Someone from our team will contact you soon.",
      icon: "success",
      button: "OK",
    });
    e.target.reset();
  };
  return (
    <>
      <footer className="theme-dark-bg footer-bg">
        <div className="tp-footer-subscribe-area-two position-relative pt-100">
          <div className="container">
            <div className="tp-footer-subscribe-bg-two theme-yellow-bg pt-30 pb-20 z-index pl-60 pr-60">
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-4">
                  <div className="tp-footer-subscribe">
                    <h3 className="tp-footer-subscribe-title">
                      Don't miss out on these discounts!
                    </h3>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-8">
                  <div className="tp-footer-subscribe-form">
                    <form method="post" className="p-0" onSubmit={sendForm}>
                      <div className="tp-footer-subscribe-form-field mb-10">
                        <input
                          type="email"
                          required
                          placeholder="Email Address"
                        />
                        <i>
                          <FaPaperPlane />
                        </i>
                      </div>
                      <div className="tp-footer-subscribe-form-btn mb-10">
                        <button type="submit" className="theme-btn text-white">
                          <i className="flaticon-enter"></i> Check-out
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-footer-area-two pt-80 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div
                  className="tp-footer-widget footer-col-1 mb-30 wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div className="tp-footer-info">
                    <div className="tp-footer-info-logo mb-15">
                      <Link to="/">
                        <img
                          src="assets/img/logo/logo-white.png"
                          className="img-fluid footer-logo"
                          alt="img not found"
                        />
                      </Link>
                    </div>
                    <p className="footer-p-about">
                      Flurosence cleaning services is a company with 6 years of
                      experience.We started off with cleaning services in a
                      small residential area and some commercial company and now
                      we are expanding our services into high rise Colleges,
                      Schools, Residential and commercial area with hygiene
                      service and maintenance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div
                  className="tp-footer-widget footer-col-2 mb-30 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="tp-footer-widget-title mb-35">Quick Links</h4>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/services">services</Link>
                    </li>
                    <li>
                      <Link to="/projects">Project</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div
                  className="tp-footer-widget  mb-30 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="tp-footer-widget-title mb-35">Our Services</h4>
                  <ul>
                    <li>
                      <Link to="/house-cleaning-service-coimbatore">
                        House Cleaning
                      </Link>
                    </li>
                    <li>
                      <Link to="/bathroom-cleaning-services-coimbatore">
                        Deep Toilet Cleaning
                      </Link>
                    </li>
                    <li>
                      <Link to="/house-painting-services-coimbatore">
                        {" "}
                        Airless Spray Painting
                      </Link>
                    </li>
                    <li>
                      <Link to="/water-tank-cleaning-services-coimbatore">
                        Water Tank Cleaning
                      </Link>
                    </li>
                    <li>
                      <Link to="/kitchen-cleaning-services-coimbatore">
                        {" "}
                        Kitchen Cleaning
                      </Link>
                    </li>
                    <li>
                      <Link to="/termite-control-coimbatore">
                        {" "}
                        A Termite Service
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div
                  className="tp-footer-widget footer-col-3 mb-30 wow fadeInUp"
                  data-wow-delay=".9s"
                >
                  <h4 className="tp-footer-widget-title mb-35">Address</h4>
                  <div className="tp-footer-info">
                    <h4 className="mb-15">
                      <a href="tel:+919500466996">+91 95004 66996</a>
                    </h4>
                    <h6 className="mb-15">
                      {" "}
                      <i>
                        {" "}
                        <FaEnvelopeOpen />{" "}
                      </i>
                      <a href="mailto:fluoresendil@gmail.com">
                        fluoresendil@gmail.com
                      </a>
                    </h6>
                    <h6 className="mb-20">
                      {" "}
                      <i>
                        {" "}
                        <FaMapMarkerAlt />{" "}
                      </i>{" "}
                      23, K. K. Pudhur Road Velandipalayam post Coimbatore
                      641025.
                    </h6>
                    <h6 className="mb-20">
                      {" "}
                      <i>
                        {" "}
                        <FaMapMarkerAlt />{" "}
                      </i>{" "}
                      24, Madalayam Street, Janatha Nagar, Saravanampatty Post,
                      Coimbatore 641035.
                    </h6>
                    <div className="tp-footer-info-social">
                      <Link to="/">
                        <i>
                          <FaFacebookF className="icon" />{" "}
                        </i>
                      </Link>
                      <Link to="/">
                        <i>
                          <BsTwitter className="icon" />{" "}
                        </i>
                      </Link>
                      <Link to="/">
                        <i>
                          <BsInstagram className="icon" />{" "}
                        </i>
                      </Link>
                      <Link to="/">
                        <i>
                          <AiOutlineGoogle className="icon" />{" "}
                        </i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-copyright-area-two bg-footer-clr footer-bt-bg z-index pt-15 pb-15">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="tp-copyright tp-copyright-two text-center">
                  <p className="m-0">
                    Copyright ©2025 <span>Fluoresen</span>. Design by{" "}
                    <a
                      className="designBy"
                      href="https://mooshatech.com/"
                      target="_blank"
                    >
                      <span>Moosha Tech</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
